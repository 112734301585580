import type { MaybeRef } from '@vueuse/core'

import { httpService } from '@/api/http.service'

import { useFetch } from '../composables/useFetch'

export const useWsAuthTokenRetrieve = ({
  enabled = false
}: {
  enabled: MaybeRef<boolean>
}) =>
  useFetch({
    queryKey: ['wsAuthTokenRetrieve'],
    queryFn: () =>
      // @ts-expect-error - waits for fix - https://panowie.atlassian.net/browse/RPS-11484
      httpService.ws.wsAuthTokenRetrieve() as Promise<{
        token: string
      }>,
    enabled,
    refetchOnWindowFocus: false
  })
