<template>
  <FormItemWrapper
    type="select"
    :has-errors="hasErrors"
    force-show-form
    editable
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #input>
      <a-form-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :name="prop"
      >
        <a-radio-group
          v-model:value="inputValue"
          v-bind="$attrs"
          :disabled="!editable || $attrs.disabled"
        />
      </a-form-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import { defineComponent } from 'vue'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import HInputMixin from '@/mixins/HInputMixin.vue'

export default defineComponent({
  components: { FormItemWrapper },
  extends: HInputMixin,

  props: {
    value: {
      type: [Boolean, String, Number]
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  emits: ['input', 'update:value'],

  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.$emit('update:value', value)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    }
  }
})
</script>
<style lang="scss" scoped></style>
