<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    :force-show-form="forceShowForm"
    :editable="editable"
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
    query-selector="textarea"
  >
    <template #text>
      <a-form-item
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
      >
        <div class="form-item-text">{{ inputValue || emptyText }}</div>
      </a-form-item>
    </template>
    <template #input="{ toggleEditMode }">
      <EmojisPicker v-if="withEmojis" @emoji-selected="inputEmoji" />

      <a-form-item
        ref="formModelItem"
        :label="label"
        :colon="labelColon"
        v-bind="errors"
        :required="required"
        :class="{
          'current-and-max-length-visible': maxLength !== undefined
        }"
        :rules="customRules"
        :name="prop"
      >
        <!-- TODO[VUE3]: Check onInput event when https://panowie.atlassian.net/browse/RPS-11203 -->
        <a-textarea
          :autofocus="autoFocus"
          :value="inputValue"
          :maxlength="maxLength"
          v-bind="{
            ...$attrs,
            onInput: event => (inputValue = event.target.value)
          }"
          @press-enter="
            event =>
              forceShowForm ? null : blurOnEnter ? event.target.blur() : null
          "
          @blur="$event => onBlur($event, toggleEditMode)"
        />
        <TextLengthValidator
          :value="inputValue"
          :max-length="maxLength"
          :characters-count-width="charactersCountWidth"
        />
      </a-form-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import { isArray } from 'lodash'
import { defineComponent } from 'vue'

import EmojisPicker from '@/components/base/EmojisPicker.vue'
import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import TextLengthValidator from '@/components/common/validators/TextLengthValidator.vue'
import { rulesMessages } from '@/constants/error-messages'
import HInputMixin from '@/mixins/HInputMixin.vue'

export default defineComponent({
  components: { TextLengthValidator, FormItemWrapper, EmojisPicker },
  mixins: [HInputMixin],

  props: {
    withEmojis: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    blurOnEnter: {
      type: Boolean,
      default: true
    },
    minLength: {
      type: Number,
      required: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    charactersCountWidth: {
      type: String,
      default: '60px'
    }
  },

  emits: ['input', 'update:value'],

  data() {
    return {
      selectionEnd: -1
    }
  },

  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.$emit('update:value', value)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    },
    customRules() {
      const userRules = isArray(this.rules) ? this.rules : [this.rules]

      const customRules = []

      if (this.required) {
        customRules.push({
          required: true,
          trigger: ['change', 'blur'],
          message: rulesMessages.required
        })
      }

      if (this.minLength) {
        customRules.push({
          min: this.minLength,
          trigger: ['change', 'blur'],
          message: rulesMessages.str.min(this.minLength)
        })
      }

      if (this.maxLength) {
        customRules.push({
          max: this.maxLength,
          trigger: ['change', 'blur'],
          message: rulesMessages.str.max(this.maxLength)
        })
      }

      return [...userRules, ...customRules]
    },
    currentLength() {
      const isString = typeof this.inputValue === 'string'
      return isString ? this.inputValue.length : 0
    }
  },

  methods: {
    onBlur($event, toggleEditMode) {
      this.selectionEnd = $event.target.selectionEnd
      const classList = $event?.relatedTarget?.classList
      const emoji = classList && classList.contains('emoji-invoker')
      if (emoji) {
        // emoji picker was clicked, so we don't close textarea
        return
      }
      toggleEditMode($event)
      if (this.prop) this.$refs.formModelItem?.onFieldBlur()
    },
    inputEmoji(emoji) {
      const currentValue = this.inputValue || ''
      const selectionEnd =
        this.selectionEnd >= 0 ? this.selectionEnd : currentValue.length
      this.inputValue =
        currentValue.substring(0, selectionEnd) +
        emoji +
        currentValue.substring(selectionEnd)
    }
  }
})
</script>
<style lang="scss" scoped>
textarea.ant-input {
  margin-bottom: 0;
}
.form-item-text {
  white-space: pre-wrap;
}

:deep(.current-and-max-length-visible) {
  .ant-form-explain {
    width: calc(100% - v-bind(charactersCountWidth));
  }
}
</style>
