import {
  useQuery,
  type QueryKey,
  type UseQueryDefinedReturnType,
  type UseQueryReturnType,
  type UndefinedInitialQueryOptions,
  type DefinedInitialQueryOptions,
  type UseQueryOptions,
  type DefaultError
} from '@tanstack/vue-query'
import { watch } from 'vue'

import { errorNotification } from '@/utils/notifications'

export function useFetch<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  tanstackOptions: UndefinedInitialQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey
  >,
  internalOptions?: {
    errorNotificationText?: string
    showErrorNotification?: boolean
  }
): UseQueryReturnType<TData, TError>

export function useFetch<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  tanstackOptions: DefinedInitialQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey
  > &
    (
      | { initialData: TQueryFnData | (() => TQueryFnData) }
      | { placeholderData: TQueryFnData | (() => TQueryFnData) }
    ),
  internalOptions?: {
    errorNotificationText?: string
    showErrorNotification?: boolean
  }
): UseQueryDefinedReturnType<TData, TError>

export function useFetch<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  tanstackOptions: UseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey
  > & {
    initialData?: TQueryFnData | (() => TQueryFnData)
    placeholderData?: TQueryFnData | (() => TQueryFnData)
  },
  internalOptions?: {
    errorNotificationText?: string
    showErrorNotification?: boolean
  }
):
  | UseQueryDefinedReturnType<TData, TError>
  | UseQueryReturnType<TData, TError> {
  const useQueryData = useQuery(tanstackOptions)

  internalOptions = {
    ...internalOptions,
    showErrorNotification:
      internalOptions?.showErrorNotification !== undefined
        ? internalOptions?.showErrorNotification
        : true
  }

  watch(
    () => useQueryData.isError.value,
    () => {
      if (
        useQueryData.isError?.value &&
        internalOptions?.showErrorNotification
      ) {
        errorNotification(internalOptions?.errorNotificationText)
      }
    },
    { immediate: true }
  )

  if (tanstackOptions?.initialData || tanstackOptions?.placeholderData)
    return useQueryData as UseQueryDefinedReturnType<TData, TError>
  return useQueryData
}
