import type {
  NavigationGuardNext,
  NavigationGuardWithThis,
  RouteRecordRaw
} from 'vue-router'
import {
  createRouter,
  NavigationFailureType,
  isNavigationFailure,
  createWebHistory
} from 'vue-router'

import store from '@/store/'
import { confirmationModal } from '@/utils/confirmation-modal'
import { checkIsRouteWithFeatureEnabled } from '@/utils/feature-flags'
import { errorNotification } from '@/utils/notifications'

import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes as RouteRecordRaw[]
})

const delphiSuggestionPreviewRouteName = 'timetable-suggestions-preview'
const timetableDetailsRouteName = 'timetable-details'

const routeGuard: NavigationGuardWithThis<undefined> = async (
  to,
  from,
  next
) => {
  document.title = to.meta.title ? `${to.meta.title} — Rosenberg` : 'Rosenberg'

  const publicRoute = to.meta.public

  if (publicRoute) {
    next()
    return
  }

  if (
    from.name === delphiSuggestionPreviewRouteName &&
    ![timetableDetailsRouteName, delphiSuggestionPreviewRouteName].includes(
      to.name?.toString() || ''
    )
  ) {
    showDelphiSuggestionLeaveModal(next)
    return
  }

  const { profiles } = store.state.userInfo
  const { permissions } = store.state.userInfo as { permissions: string[] }

  if (!publicRoute && !profiles) {
    errorNotification('This page is only for logged in users. Log in!')
    next({ name: 'Login', query: { next: to.path } })
    return
  }

  const routePermissions: string[] = (to.meta?.permissions ?? []) as string[]
  if (routePermissions) {
    const hasPermission = routePermissions.some(routePermission =>
      permissions.includes(routePermission)
    )

    if (!hasPermission || !checkIsRouteWithFeatureEnabled(to)) {
      errorNotification("You don't have permission to view this page.")
      next({ name: 'Login' })
      return
    }
  }

  next()
}

router.beforeEach(routeGuard)

if (import.meta.env.PROD) {
  const handleDuplicatedNavigation = (error: Error) => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated))
      throw error
  }

  const originalPush = router.push
  router.push = function push(to) {
    return originalPush.call(this, to).catch(handleDuplicatedNavigation)
  }

  const originalReplace = router.replace
  router.replace = function replace(to) {
    return originalReplace.call(this, to).catch(handleDuplicatedNavigation)
  }
}

const showDelphiSuggestionLeaveModal = (next: NavigationGuardNext) => {
  confirmationModal({
    title: 'Leave changes preview',
    content:
      'Do you want to leave timetable changes preview? You can apply the move here or in the original tab.',
    okText: 'Leave',
    centered: true,
    onOk: () => {
      next()
    }
  })
}

export default router
