import { isEqual } from 'lodash'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function useQueryModels() {
  const route = useRoute()
  const router = useRouter()

  const query = computed(() => route.query)

  const getQueryParam = (name: string | string[]) => {
    if (Array.isArray(name)) {
      return getRangeQueryParams(name)
    }
    const value = query.value[name]

    if (value === '') return undefined

    if (value === 'true') return true

    if (value === 'false') return false

    const checkIfIntAndParse = (val: string | null) =>
      isNaN(Number(val)) || val === null ? val : parseInt(val)

    return Array.isArray(value)
      ? value.map(checkIfIntAndParse)
      : checkIfIntAndParse(value)
  }

  const getRangeQueryParams = (name: string[]) => [
    query.value[name[0]],
    query.value[name[1]]
  ]

  type QueryParam = {
    name: string | string[]
    value: undefined | null | string | number | string[] | number[]
  }

  const setQueryParams = async (params: QueryParam[]) => {
    const newQueryParams = { ...query.value }

    params.forEach(({ name, value }) => {
      if (getQueryParam(name) === value) return

      if (value === undefined || value === null) {
        if (Array.isArray(name)) {
          delete newQueryParams[name[0]]
          delete newQueryParams[name[1]]
        } else {
          delete newQueryParams[name]
        }
      } else {
        // if name is array -> query is range date
        if (Array.isArray(name) && Array.isArray(value)) {
          const [first, second] = name
          const [firstValue, secondValue] = value
          newQueryParams[first] = firstValue?.toString()
          newQueryParams[second] = secondValue?.toString()
        }
        if (Array.isArray(name)) return

        newQueryParams[name] = Array.isArray(value)
          ? value.map(String)
          : value.toString()
      }
    })

    if (!isEqual(newQueryParams, query.value)) {
      await router.replace({
        name: route.name || '',
        params: route.params,
        query: newQueryParams
      })
    }
  }

  const setQueryParam = async (
    name: QueryParam['name'],
    value: QueryParam['value']
  ) => {
    await setQueryParams([{ name, value }])
  }

  const mapBooleanQuery = (paramName: string) => {
    const paramValue = getQueryParam(paramName)
    if (paramValue === undefined) return paramValue
    return Boolean(paramValue)
  }

  return { getQueryParam, setQueryParam, setQueryParams, mapBooleanQuery }
}
