import { Modal } from 'ant-design-vue'
import { provide, ref } from 'vue'
import { useRoute } from 'vue-router'

import { assertIsDefined } from '@/functions/helpers'
import { randomUUID } from '@/functions/utils'
import router from '@/router'

import { DelphiSessionKey } from './keys'
import type { DelphiChannelEventData } from './types'
import { useMultiTabBroadcast } from '../useMultiTabBroadcast'
import type {
  AdditionalQueryParams,
  DraftActivitySuggesterTaskEvent,
  SuggesterUserSession
} from '../useNotificationsWebsocket/types'

let USE_DELPHI_INSTANCE: string | null = null

export function useDelphi() {
  assertIsDefined(
    !USE_DELPHI_INSTANCE,
    'useDelphi already initialized. Only one instance is allowed.'
  )

  USE_DELPHI_INSTANCE = randomUUID()

  const delphiSession = ref<SuggesterUserSession>()
  const setDelphiSession = (value?: SuggesterUserSession) =>
    (delphiSession.value = value)

  const route = useRoute()

  useMultiTabBroadcast<DelphiChannelEventData, DelphiChannelEventData>({
    channelName: 'delphi-channel',
    onEvent: event => {
      if (event.data.type === 'restart-delphi-session') {
        Modal.destroyAll()
      }
    }
  })

  const goToTimetableDetails = (
    eventData: DraftActivitySuggesterTaskEvent,
    additionalQueryParams: AdditionalQueryParams
  ) => {
    const timetableDetailsPath = {
      name: 'timetable-details',
      params: {
        timetableId: eventData.timetable_id.toString(),
        rotaId: eventData.rota_timetable_id?.toString()
      },
      query: {
        ...(route.name === 'timetable-details' ? route.query : {}),
        backPath: '/planner/timetables',
        backName: 'Timetables & Boarding Rotas',
        ...additionalQueryParams
      }
    }

    // TODO: For unknown reasons the rotaId parameter is required, but not providing it is one of the correct behaviors. After setting rotaId as an empty string, push does not work properly
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    router.push(timetableDetailsPath)
  }

  provide(DelphiSessionKey, { delphiSession, setDelphiSession })

  return {
    goToTimetableDetails,
    setDelphiSession
  }
}
