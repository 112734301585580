export const isNumber = (value: unknown): value is number =>
  typeof value === 'number'

export const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean'

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const isArrayOfNumbers = (value: unknown): value is number[] =>
  Array.isArray(value) && value.every(item => typeof item === 'number')
