<template>
  <FormItemWrapper
    type="input"
    :has-errors="hasErrors"
    force-show-form
    editable
    :no-wrap-text="noWrapText"
    :focus-background-color="focusBackgroundColor"
  >
    <template #input>
      <a-form-item
        ref="formModelItem"
        v-bind="errors"
        :required="required"
        :rules="customRules"
        :name="prop"
      >
        <a-checkbox :disabled="!editable" :checked="inputValue" v-bind="$attrs">
          {{ label }}
        </a-checkbox>
      </a-form-item>
    </template>
  </FormItemWrapper>
</template>
<script>
import { defineComponent } from 'vue'

import FormItemWrapper from '@/components/common/forms/FormItemWrapper.vue'
import HInputMixin from '@/mixins/HInputMixin.vue'

export default defineComponent({
  components: { FormItemWrapper },
  extends: HInputMixin,

  props: {
    value: {
      type: Boolean
    }
  },

  emits: ['input', 'update:value'],

  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value)
        this.$emit('update:value', value)
        if (this.prop) this.$refs.formModelItem?.onFieldChange()
      },
      get() {
        return this.value
      }
    }
  }
})
</script>
<style lang="scss" scoped>
textarea.ant-input {
  margin-bottom: 0;
}
</style>
