<template>
  <EmojiPickerComponent :search="search" @emoji="selectEmoji">
    <template #emoji-invoker="{ events: { click: clickEvent } }">
      <div class="invoker-conteiner" @click.stop="clickEvent">
        <DeprecatedIcon class="emoji-invoker" type="smile" />
      </div>
    </template>
    <template #emoji-picker="{ emojis }">
      <div class="picker-container">
        <div class="emoji-picker">
          <a-input-search v-model:value="search" class="search" type="text" />
          <div class="container">
            <div
              v-for="(emojiGroup, category) in emojis"
              :key="category"
              class="category-container"
            >
              <h5>{{ category }}</h5>
              <div
                v-for="(emoji, emojiName) in emojiGroup"
                :key="emojiName"
                class="emoji-container"
                :title="emojiName"
                @click="selectEmoji(emoji)"
                v-html="toTwemoji(emoji)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </EmojiPickerComponent>
</template>
<script setup>
import { parse } from '@twemoji/parser'
import { ref } from 'vue'
import EmojiPickerComponent from 'vue-emoji-picker/src/Components/EmojiPicker.vue'

import DeprecatedIcon from '../icons/DeprecatedIcon.vue'

const emit = defineEmits(['emoji-selected'])

const search = ref('')

const selectEmoji = emoji => {
  emit('emoji-selected', emoji)
}

const toTwemoji = emoji => parse(emoji)[0].text
</script>
<style scoped lang="scss">
.invoker-conteiner {
  position: relative;
}

.emoji-invoker {
  color: $neutral-4;
  position: absolute;
  top: 6px;
  z-index: 1;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
  color: $neutral-8;
}

.emoji-container {
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
}

.picker-container {
  position: relative;
}

.emoji-picker {
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 2;
  border: 1px solid $grey-border;
  box-sizing: border-box;
  border-radius: 16px;
  background: $white;
  box-shadow: 1px 1px 8px $neutral-3;
}

.search {
  padding: 4px;

  :deep(.ant-input) {
    border: 1px solid $grey-border !important;
    box-shadow: none !important;
  }
}

.emoji-picker h5 {
  margin-bottom: 0;
  margin-left: 10px;
}

.container {
  overflow-y: scroll;
  width: 390px;
  height: 350px;
}
</style>
