import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { computed } from 'vue'

import { httpService } from '@/api/http.service'
import { useFetch } from '@/composables/useFetch'

import { usePermissions } from './usePermissions'

export const useNewStudentsCounter = () => {
  const { isSuperUser } = usePermissions()
  const queryClient = useQueryClient()

  const newStudentsCounter = computed(() => data.value?.new_students_count)

  const { data } = useFetch({
    queryKey: ['studentsNewCounterRetrieve'],
    queryFn: () => httpService.students.studentsNewCounterRetrieve(),
    enabled: isSuperUser
  })

  const { mutate } = useMutation({
    mutationFn: () => httpService.students.studentsNewCounterUpdate()
  })

  const setNewStudentsCounter = (value: number | null) =>
    queryClient.setQueryData(['studentsNewCounterRetrieve'], {
      new_students_count: value
    })

  const resetNewStudentsCounter = () => {
    if (newStudentsCounter.value === 0 || !isSuperUser.value) return
    mutate()
  }

  return {
    newStudentsCounter,
    resetNewStudentsCounter,
    setNewStudentsCounter
  }
}
