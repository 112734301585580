import type { RedirectRouteLocationRaw, Route } from '@/types/route'

import { filterRoutesWithPermissions } from './filterRoutesWithPermissions'

const getFirstPossibleRedirectAsRoute = (routes: Route[]) =>
  filterRoutesWithPermissions(routes)[0]

export const getFirstPossibleRoute = (
  routes: Route[]
): RedirectRouteLocationRaw => ({
  name: getFirstPossibleRedirectAsRoute(routes).name
})

export const getDeepRedirectRoute = (
  route: Route
): RedirectRouteLocationRaw => {
  if (route?.redirect && route?.children)
    return getDeepRedirectRoute(getFirstPossibleRedirectAsRoute(route.children))
  return { name: route.name }
}
