import { notification } from 'ant-design-vue'
import type { NotificationArgsProps } from 'ant-design-vue/lib/notification'
import type { VNodeTypes } from 'vue'

import { DEFAULT_ERROR_MESSAGE } from '@/constants/error-messages'

type InnerNotificationOptions = {
  duration?: number
  class?: string
  key?: string
  icon?: VNodeTypes
  description?: VNodeTypes
  onClose?: () => void
}

export function errorNotification(
  errorText?: VNodeTypes,
  options?: InnerNotificationOptions
) {
  notification.error({
    message: errorText || DEFAULT_ERROR_MESSAGE,
    description: '',
    ...options
  })
}

export function successNotification(
  successText: VNodeTypes,
  options?: InnerNotificationOptions
) {
  notification.success({
    message: successText,
    description: '',
    ...options
  })
}

export function infoNotification(
  successText: VNodeTypes,
  options?: InnerNotificationOptions
) {
  notification.info({
    message: successText,
    description: '',
    ...options
  })
}

export function warningNotification(
  warningText: VNodeTypes,
  options?: InnerNotificationOptions
) {
  notification.warning({
    message: warningText,
    description: '',
    ...options
  })
}

export function openNotification(options: NotificationArgsProps) {
  notification.open(options)
}

export function closeNotification(key: string) {
  notification.close(key)
}

export function destroyNotification() {
  notification.destroy()
}
