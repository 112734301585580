import { useMutation, useQueryClient } from '@tanstack/vue-query'

import type {
  NewStudentsBannerResponse,
  StudentIdsListRequest
} from '@/api/__generated-api'
import { httpService } from '@/api/http.service'
import { useFetch } from '@/composables/useFetch'

import { usePermissions } from './usePermissions'

export const useNewStudentsBanner = () => {
  const { isSuperUser } = usePermissions()
  const queryClient = useQueryClient()

  const { data: newStudentEntries } = useFetch({
    queryKey: ['studentsNewBannerList'],
    queryFn: () => httpService.students.studentsNewBannerList(),
    enabled: isSuperUser
  })

  const { mutateAsync: markStudentsAsSeen } = useMutation({
    mutationFn: (studentIds: StudentIdsListRequest) =>
      httpService.students.studentsNewBannerCreate(studentIds)
  })

  const setNewStudentEntries = (value: NewStudentsBannerResponse) =>
    queryClient.setQueryData(['studentsNewBannerList'], value)

  return {
    markStudentsAsSeen,
    newStudentEntries,
    setNewStudentEntries
  }
}
