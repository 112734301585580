import { usePermissions } from '@/composables/usePermissions'
import { type Route } from '@/types/route'

export const filterRoutesWithPermissions = (routes: Route[]): Route[] =>
  routes.reduce((newRoutes: Route[], route: Route) => {
    const { hasPermission } = usePermissions()
    const isValid =
      !route.meta?.permissions ||
      (route.meta.permissions &&
        !!route.meta.permissions.filter(hasPermission).length)

    if (!isValid) return newRoutes
    else if (!route.children) return [...newRoutes, route]
    const children: Route[] = filterRoutesWithPermissions(route.children)
    return children.length ? [...newRoutes, { ...route, children }] : newRoutes
  }, [] as Route[])
