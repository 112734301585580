import { keepPreviousData } from '@tanstack/vue-query'
import type { MaybeRef } from '@vueuse/core'

import { httpService } from '@/api/http.service'
import { useFetch } from '@/composables/useFetch'

export function useUserInfo(enabled: MaybeRef<boolean> = true) {
  return useFetch({
    queryKey: ['userInfo'],
    queryFn: () => httpService.users.usersMeRetrieve(),
    placeholderData: keepPreviousData,
    enabled
  })
}
