import type { RouteLocationNormalizedLoadedGeneric } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'

import type { Expand } from '@/types'
import type { RosenLocation, RosenRouteNames } from '@/types/route'

type RosenAppRoute = Expand<
  Omit<RouteLocationNormalizedLoadedGeneric, 'name'> & {
    name: RosenRouteNames
  }
>

export const useRosenRoute = () => {
  const route = useRoute() as RosenAppRoute
  const router = useRouter()

  const goToRosenRoute = (
    location: RosenLocation,
    method: 'replace' | 'push' = 'replace'
  ) => {
    router[method](location)
  }

  const isActiveLink = (name: string) =>
    route.matched.some(location => location.name === name)

  return { route, goToRosenRoute, isActiveLink }
}
