import axios from 'axios'

import router from '@/router'
import store from '@/store'
import { errorNotification } from '@/utils/notifications'

axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = window.localStorage.getItem('token')
    if (window.localStorage.getItem('lang')) {
      config.headers['Accept-Language'] = window.localStorage.getItem('lang')
    }

    const safeModeDisabled = window.localStorage.getItem('safeModeDisabled')

    if (safeModeDisabled) {
      config.headers['safe-mode-disabled'] = safeModeDisabled
    }
    config.paramsSerializer = {
      indexes: null
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => response,
  error => {
    const errorResponse = error?.response
    const forbidden = 403 === errorResponse?.status
    const unauthorized = 401 === errorResponse?.status
    if (forbidden && !error.config.metadata?.disable403Redirection) {
      errorNotification("You don't have permission to view this page.", {
        key: 'permission'
      })
      router.push('/')
    } else if (unauthorized) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('websocketToken')
      window.dispatchEvent(new Event('ws-disconnect'))
      store.commit('clearUserInfo')
    }

    return Promise.reject(error)
  }
)

export function prepareURL(url, urlParams) {
  urlParams = urlParams || {}
  if (typeof urlParams !== 'object') {
    urlParams = {
      id: urlParams
    }
  }

  const convertURLFragment = fragment =>
    fragment.startsWith(':') ? urlParams[fragment.slice(1)] : fragment
  return url.split('/').map(convertURLFragment).join('/').replace('//', '/')
}

export function getQuery() {
  const query = router.history.current.query
  const pageSize = query.page_size || 50

  const params = {
    ...query,
    page_size: pageSize
  }

  return params
}

export default function (url) {
  return {
    fetch: (urlParams, payload) =>
      axios.get(prepareURL(url, urlParams), payload),
    all: (payload = {}) => {
      const params = {
        page_size: 'max',
        ...payload
      }
      return axios.get(prepareURL(url), { params })
    },
    update: (urlParams, payload, headers = {}) =>
      axios.put(prepareURL(url, urlParams), payload, { headers }),
    patch: (urlParams, payload, headers = {}) =>
      axios.patch(prepareURL(url, urlParams), payload, { headers }),
    get: (urlParams, payload) => axios.get(prepareURL(url, urlParams), payload),
    save: (payload, headers = {}, urlParams = {}) =>
      axios.post(prepareURL(url, urlParams), payload, headers),
    delete: urlParams => axios.delete(prepareURL(url, urlParams))
  }
}
