<template>
  <BaseButton type="link" class="back-to-top" @click="scrollToTop">
    Back to top
    <DeprecatedIcon type="arrow-up" />
  </BaseButton>
</template>

<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'

import DeprecatedIcon from '../icons/DeprecatedIcon.vue'

const props = withDefaults(
  defineProps<{
    contentWrapperHtmlId?: string
  }>(),
  {
    contentWrapperHtmlId: 'content-wrapper'
  }
)

const scrollToTop = () => {
  const contentWrapper = document.getElementById(props.contentWrapperHtmlId)
  contentWrapper?.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.back-to-top {
  font-weight: 400;
  color: var(--light-gray-color);

  &:hover {
    color: var(--black-color);
  }
}
</style>
