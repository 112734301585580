<template>
  <a-date-picker v-model:value="valueMoment" v-bind="passedProps" />
</template>
<script>
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  model: {
    event: 'change'
  },

  props: {
    value: {
      type: [String],
      required: false
    }
  },

  emits: ['update:value', 'change'],

  computed: {
    valueMoment: {
      set(value) {
        const parsedValue = value ? value.format('YYYY-MM-DD') : value
        this.$emit('update:value', parsedValue)
        this.$emit('change', parsedValue)
      },
      get() {
        return this.value ? moment(this.value) : this.value
      }
    },
    passedProps() {
      return this.$attrs
    }
  }
})
</script>
