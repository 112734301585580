<template>
  <AntPagination
    v-if="pagination"
    :current="pagination.current"
    :page-size="pagination.pageSize"
    :total="pagination.total"
    @change="onChange"
  />
</template>

<script setup lang="tsx">
import { Pagination as AntPagination } from 'ant-design-vue'
import { nextTick } from 'vue'

import type { Pagination } from '@/types/pagination'

const props = defineProps<{
  pagination?: Pagination | false
}>()

const onChange = (page: number, pageSize: number) => {
  nextTick(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))
  if (props.pagination && props.pagination.onChange) {
    props.pagination.onChange(page, pageSize)
  }
}
</script>

<style lang="scss" scoped>
:deep(.ant-pagination-item) {
  font-family: 'Inter', sans-serif;
  letter-spacing: normal;

  &.ant-pagination-item-active {
    font-weight: 400;
  }
}

:deep(.ant-pagination-jump-prev),
:deep(.ant-pagination-jump-next) {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  outline: 0;
}

.ant-pagination-item-ellipsis {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  color: var(--black-color);
  letter-spacing: normal;
}
</style>
