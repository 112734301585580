<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Student">
      <path
        id="Vector"
        d="M7.98854 14.0452L3.16559 11.4155V7.41545L0.569336 6L7.98854 1.95085L15.43 6V11.3492H14.2166V6.69045L12.8142 7.41545V11.4155L7.98854 14.0452ZM7.98854 8.66692L12.8872 6L7.98854 3.36642L3.11209 6L7.98854 8.66692ZM7.98854 12.6685L11.5981 10.6879V8.10725L7.98854 10.0492L4.37899 8.08502V10.6879L7.98854 12.6685Z"
        fill="#4D4D4D"
      />
    </g>
  </svg>
</template>
