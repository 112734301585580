<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill-opacity="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.66667 15L1 11.6667V1L5.66667 4.33333L10.3333 1L15 4.33333V15L10.3333 11.6667L5.66667 15V15Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66663 4.33331V15"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.3334 11.6667V1.02069"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
