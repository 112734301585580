<template>
  <AntButton
    :type="buttonType"
    :data-cy="dataCy"
    class="base-button"
    :loading="loading"
    :disabled="disabled"
    :block="block"
    :size="buttonSize"
    :html-type="isSubmitType ? 'submit' : 'button'"
    :danger="isDanger"
    :ghost="ghost"
    :class="{ 'blue-link': isBlueLink }"
    @click="onClick"
  >
    <!-- @slot Use this slot to place content inside button. -->
    <slot>
      <div v-if="externalLinkButton" class="external-link-button">
        {{ text }}
        <ExternalLinkIcon color="currentColor" />
      </div>
      <template v-else>{{ text }}</template>
    </slot>

    <template #icon>
      <slot name="icon">
        <DeprecatedIcon v-if="isString(icon)" :type="icon" />
        <component :is="icon" v-else />
      </slot>
    </template>
  </AntButton>
</template>

<script setup lang="ts">
import { Button as AntButton } from 'ant-design-vue'
import { computed, type Component } from 'vue'

import ExternalLinkIcon from '@/components/icons/ExternalLinkIcon.vue'
import { isString } from '@/utils/type-guards'

import type { IconString } from '../icons/DeprecatedIcon.vue'
import DeprecatedIcon from '../icons/DeprecatedIcon.vue'

export type ButtonType = 'primary' | 'secondary' | 'link' | 'dashed'
type ButtonSize = 'small' | 'large' | 'default'
export type ButtonProps = {
  block?: boolean
  dataCy?: string
  disabled?: boolean
  externalLinkButton?: boolean
  icon?: Component | IconString
  isSubmitType?: boolean
  isBlueLink?: boolean
  loading?: boolean
  danger?: boolean
  ghost?: boolean
  size?: ButtonSize
  /**
   * @deprecated use default slot instead
   */
  text?: string
  type?: ButtonType
}

const props = withDefaults(defineProps<ButtonProps>(), {
  block: false,
  disabled: false,
  isBlueLink: false,
  isSubmitType: false,
  loading: false,
  danger: false,
  ghost: false,
  size: 'default',
  text: '',
  type: 'primary'
})

const emit = defineEmits<{
  (event: 'click', value: MouseEvent): void
}>()

const buttonSize = computed(() =>
  // Default size should be passed as undefined
  props.size === 'default' ? undefined : props.size
)

// @ts-expect-error -- 'danger' type is removed from ant, but we use it for backward compatibility. Its not typed intentionally.
const isDanger = computed(() => props.danger || props.type === 'danger')

const buttonType = computed(() => {
  // @ts-expect-error -- Danger type is now a dedicated property, so here we map it for a previous behavior. We keep it for backward compatibility.
  if (props.type === 'danger') return 'primary'
  // Secondary type is a default type, so we map it to default
  if (props.type === 'secondary') return 'default'
  return props.type
})

const onClick = (event: MouseEvent) => {
  emit('click', event)
}
</script>

<style lang="scss" scoped>
.ant-btn :deep(> span::first-letter) {
  text-transform: capitalize;
}

.external-link-button {
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
}

// Ant somehow incorrectly applies styles to the button, so we need to override them. Without this, ghost danger button has white text on hover
.base-button.ant-btn-background-ghost.ant-btn-dangerous:hover,
.base-button.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ffa39e !important;
}
</style>
