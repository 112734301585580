// eslint-disable-next-line import/order
import { createApp } from 'vue'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/vue'

// eslint-disable-next-line import/order
import App from './App.vue'
// Changing the order of imports for the router is causing issues in the application.
// eslint-disable-next-line
import router from './router'

// Maz UI styles for
import 'maz-ui/css/main.css'

// Ant components
import '@/styles/theme/index.less'

// Global components

// Scss
import '@/styles/global.scss'

// Plugins
import { VueQueryPlugin } from '@tanstack/vue-query'
import Antd, { Form } from 'ant-design-vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeekfrom from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar-next'

import ListColsHeader from '@/components/common/ListColsHeader.vue'
import ScrollX from '@/components/common/ScrollX.vue'
import Upload from '@/components/common/Upload.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'
import HCheckBox from '@/components/inputs/HCheckBox.vue'
import HDatePicker from '@/components/inputs/HDatePicker.vue'
import HInput from '@/components/inputs/HInput.vue'
import HInputNumber from '@/components/inputs/HInputNumber.vue'
import HRadioGroup from '@/components/inputs/HRadioGroup.vue'
import HRangePicker from '@/components/inputs/HRangePicker.vue'
import HSelect from '@/components/inputs/HSelect.vue'
import HTextArea from '@/components/inputs/HTextArea.vue'
import HTimePicker from '@/components/inputs/HTimePicker.vue'
import AList from '@/components/layout/AList.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import { defaultErrorMessages } from '@/constants/error-messages'
import { setupGlobalMixin } from '@/mixins'
import { errorNotification } from '@/utils/notifications'

import store from './store'
import { confirmationModal } from './utils/confirmation-modal'

Form.props.validateMessages.default = () => defaultErrorMessages

// DayJs plugins
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isoWeekfrom)
dayjs.extend(customParseFormat)

export const app = createApp(App)

app.use(router)
app.use(store)

app.use(Antd)

setupGlobalMixin(app)

app.component('ListColsHeader', ListColsHeader)
app.component('AList', AList)
app.component('Upload', Upload)
app.component('DatePicker', DatePicker)
app.component('PageHeader', PageHeader)
app.component('HSelect', HSelect)
app.component('HInput', HInput)
app.component('HInputNumber', HInputNumber)
app.component('HTextArea', HTextArea)
app.component('HTimePicker', HTimePicker)
app.component('HDatePicker', HDatePicker)
app.component('HRangePicker', HRangePicker)
app.component('HCheckBox', HCheckBox)
app.component('HRadioGroup', HRadioGroup)
app.component('ScrollX', ScrollX)

const IS_PRODUCTION = ['production', 'stage', 'summer-production'].includes(
  import.meta.env.VITE_ENV || 'production'
)

app.use(
  VueGtag,
  {
    appName: 'Rosenberg Panel',
    config: { id: import.meta.env.VITE_GTAG_ID },
    pageTrackerScreenviewEnabled: true
  },
  router
)

if (import.meta.env.VITE_HOTJAR_ID) {
  app.use(Hotjar, {
    id: import.meta.env.VITE_HOTJAR_ID,
    isProduction: true
  })
}

if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

app.config.globalProperties.$errorNotification = errorNotification
app.config.globalProperties.$confirm = confirmationModal

Sentry.init({
  app,
  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.VITE_ENV || 'production',
  dsn: 'https://b9edc2492cdc4175aa77d37f787db35a@o1382323.ingest.sentry.io/4505487492841472',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: IS_PRODUCTION ? 0.15 : 1.0, // Capture 15% of the transactions in production, 100% in development
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(VueQueryPlugin)

app.mount('#app')
