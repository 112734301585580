<template>
  <div id="app" class="app">
    <div
      v-if="envHeaderConfig"
      class="app__env-header"
      :style="{ background: envHeaderConfig.background }"
    >
      {{ envHeaderConfig.text }} - environment
    </div>

    <a-config-provider :locale="localeEnGb">
      <RouterView v-slot="{ Component }">
        <Transition name="fade-up" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </a-config-provider>

    <VersionChecker />
  </div>
</template>

<script setup lang="ts">
import 'moment/locale/en-gb'
import { useMutation } from '@tanstack/vue-query'
import localeEnGb from 'ant-design-vue/es/locale/en_GB'
import moment from 'moment'
import { onMounted, ref, onUnmounted, watch, provide, computed } from 'vue'

import VersionChecker from '@/components/layout/VersionChecker.vue'
import { useBoardingRotaNotification } from '@/composables/useBoardingRotaNotification'
import { useDelphi } from '@/composables/useDelphi/useDelphi'
import { useModal } from '@/composables/useModal'
import { useNotificationsWebsocket } from '@/composables/useNotificationsWebsocket/useNotificationsWebsocket'
import { usePermissions } from '@/composables/usePermissions'
import { useUserInfo } from '@/queries/useUserInfo'
import { useWsAuthTokenRetrieve } from '@/queries/useWebSocket'
import store from '@/store'

import { httpService } from './api/http.service'

moment.locale('en-gb')

const markerWidget = ref()

provide('markerWidget', markerWidget)

let modalVisibilityObserver: MutationObserver | undefined

const { isLoggedIn, isStaff, isSuperUser, userProfiles } = usePermissions()

const { data: userInfo } = useUserInfo(isLoggedIn)

const { checkStatus, setTaskId, createInfoNotification } =
  useBoardingRotaNotification()

const { addModalVisibilityListener, removeModalVisibilityListener } = useModal()

const { data: wsAuthTokenData, dataUpdatedAt: wsAuthTokenDataUpdatedAt } =
  useWsAuthTokenRetrieve({
    enabled: isLoggedIn
  })

const { goToTimetableDetails, setDelphiSession } = useDelphi()

const { mutate: regenerateTaeGenerationAfterFail } = useMutation({
  mutationFn: ({
    taskId,
    timetableId
  }: {
    taskId: number
    timetableId: number
  }) =>
    httpService.planner.plannerTaeRegenerateCreate(
      taskId,
      timetableId.toString()
    )
})

const { open } = useNotificationsWebsocket({
  goToTimetableDetails,
  setDelphiSession,
  regenerateTaeGenerationAfterFail
})

const resetMarkerWidget = () => {
  markerWidget.value?.unload()
  markerWidget.value = undefined
}

const initMarkerWidget = async () => {
  const markerId = import.meta.env.VITE_MARKERIO_ID

  if (!markerId || !userInfo.value?.can_report_bug) resetMarkerWidget()
  else if (!markerWidget.value) {
    try {
      const markerSDK = (await import('@marker.io/browser')).default
      markerWidget.value = await markerSDK.loadWidget({
        project: markerId
      })
    } catch {
      resetMarkerWidget()
    }
  }
}

const setSafeMode = () => {
  if (isSuperUser.value && userProfiles.value.length >= 1) {
    window.localStorage.setItem('safeModeDisabled', 'true')
    store.commit('setSafeModeDisabled', true)
    return
  }

  if (isStaff.value && userProfiles.value.length > 1) {
    const safeModeDisabled =
      window.localStorage.getItem('safeModeDisabled') === 'true'

    window.localStorage.setItem('safeModeDisabled', `${safeModeDisabled}`)
    store.commit('setSafeModeDisabled', safeModeDisabled)
    return
  }

  window.localStorage.removeItem('safeModeDisabled')
}

const envHeaderConfig = computed(() => {
  const envValue: string | undefined = import.meta.env.VITE_ENV_HEADER_CONFIG
  if (!envValue) return

  const [text, background] = envValue.split(', ')
  return {
    text,
    background
  }
})

watch(
  () => userInfo.value,
  user => {
    if (user?.id) setSafeMode()
    initMarkerWidget()
  }
)

watch(
  () => wsAuthTokenDataUpdatedAt.value,
  () => {
    if (!wsAuthTokenData.value) return
    window.localStorage.setItem('websocketToken', wsAuthTokenData.value.token)
    open()
  }
)

const fetchUserInfo = () => {
  if (!window.localStorage.getItem('token')) return
  store.dispatch('fetchUserInfo')
}

onMounted(() => {
  fetchUserInfo()

  const boardingRotaRequestId = localStorage.getItem('boardingRotaRequestId')

  if (boardingRotaRequestId) {
    setTaskId(Number(boardingRotaRequestId))
    createInfoNotification()
    checkStatus()
  }

  modalVisibilityObserver = addModalVisibilityListener()

  if (isLoggedIn.value) store.dispatch('fetchCommonData')
})

onUnmounted(() => {
  removeModalVisibilityListener(modalVisibilityObserver)
  resetMarkerWidget()
})
</script>

<style lang="scss" scoped>
.app {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__env-header {
    text-align: center;
    color: white;
    font-size: 12px;
  }
}
</style>
